import React, { useState, useEffect } from "react";
import { Button, Popover, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SortButton from "../../SortButton/SortButton";
import { useDispatch } from "react-redux";
import { setSort } from "../../../store/Filter/filterReducers";
const sortOptions = [
  {
    name: "Distance from place of interest",
    id: "distance",
  },
  {
    id: "popularity",
    name: "Popularity",
  },
  {
    id: "class_descending",
    name: "Stars (5 to 0)",
  },
  {
    id: "class_ascending",
    name: "Stars (0 to 5)",
  },
  {
    id: "bayesian_review_score",
    name: "Guest review score",
  },
  {
    id: "price",
    name: "Price (low to high)",
  },
];
const FilterButton = ({
  label,
  filterKey,
  initialValues = {},
  children,
  onApply,
  onClear,
  applied = false,
  setAppliedFilters,
  isDisabled,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [tempValues, setTempValues] = useState(initialValues);

  useEffect(() => {
    setTempValues(initialValues);
  }, [initialValues]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    if (onApply) {
      onApply(filterKey, tempValues);
    }
    setAppliedFilters((prev) => ({ ...prev, [filterKey]: true }));
    handleClose();
  };

  const handleClear = () => {
    if (onClear) {
      onClear(filterKey);
    }
    setTempValues(initialValues);
    setAppliedFilters((prev) => ({ ...prev, [filterKey]: false }));
    handleClose();
  };

  const handleChange = (newValues) => {
    setTempValues((prev) => ({
      ...prev,
      ...newValues,
    }));
  };

  function handleSorting(option) {
    dispatch(setSort({ sort: option, key: filterKey, isRefatched: true }));
  }

  const open = Boolean(anchorEl);
  const filterStyle = {
    rent: { popBg: `bg-[#96BB00]`, tabBg: "#96BB00" },
    monthlyStay: { popBg: `bg-[#F23C03]`, tabBg: "#F23C03" },
    hotels: { popBg: `bg-[#1B66FC]`, tabBg: "#1B66FC" },
    shortTerm: { popBg: `bg-[#4a90e2]`, tabBg: "#4a90e2" },
    buy: { popBg: `bg-[#F99500]`, tabBg: "#F99500" },
  };
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="flex flex-1 items-center">
      {/* Sort Button */}
      {["hotels", "shortTerm"].includes(filterKey) && (
        <SortButton
          filterKey={filterKey}
          isOpen={open}
          applied={applied}
          sortOptions={sortOptions}
          handleSorting={handleSorting}
          defaultOption={"popularity"}
        />
      )}

      {/* Label Button */}
      <Button
        aria-describedby={id}
        className="flex-grow"
        sx={{
          backgroundColor: open
            ? filterStyle?.[filterKey]?.tabBg
            : applied
            ? filterStyle?.[filterKey]?.tabBg
            : "#ffffff",
          color: open ? "#fff" : applied ? "#ffffff" : "black",
          "&:hover": {
            backgroundColor: "lightgray",
          },
          display: "inline-flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "6px 8px",
          whiteSpace: "nowrap",
        }}
        onClick={handleClick} // Only this button triggers the popover
      >
        <div className="text-[12px]">{label}</div>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          paper: "w-72 max-w-72 !max-h-[400px]",
        }}
      >
        <div
          className={`flex flex-col gap-3 ${filterStyle?.[filterKey]?.popBg} !text-[#fff]`}
          style={{ position: "relative", height: "400px" }}
        >
          {/* Close Icon Button */}
          <IconButton
            onClick={handleClose}
            style={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#fff",
              zIndex: 1,
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>

          {!isDisabled && (
            <React.Fragment>
              <div
                className="flex-grow overflow-auto p-3"
                style={{ marginBottom: "60px" }}
              >
                {React.Children.map(children, (child) =>
                  child && React.isValidElement(child)
                    ? React.cloneElement(child, {
                        onChange: handleChange,
                        value: tempValues,
                      })
                    : null
                )}
              </div>

              {/* Fixed position for Clear and Apply buttons */}
              <div
                className="flex justify-end gap-2 p-3"
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: filterStyle?.[filterKey]?.popBg,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleClear}
                  sx={{
                    color: "#fff",
                    borderColor: "#fff",
                    fontSize: "0.75rem",
                    padding: "4px 8px",
                    "&:hover": {
                      borderColor: "#fff",
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                  }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApply}
                  sx={{
                    fontSize: "0.75rem",
                    padding: "4px 8px",
                  }}
                >
                  Apply
                </Button>
              </div>
            </React.Fragment>
          )}

          {isDisabled && (
            <div className="flex justify-center items-center h-full px-2">
              Rent and Buy only available in Portugal, Spain, and Italy.
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default FilterButton;
