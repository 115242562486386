import React, { useState } from "react";
import { Checkbox, Chip, FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { SmokeFree, SmokingRoomsRounded } from "@mui/icons-material";

const CustomCheckboxStyle = styled(Checkbox)(({ theme }) => ({
  color: "#fff", // Default color for the border and checkmark
  "&.Mui-checked": {
    color: "#fff", // Color when checked
  },
}));
const CustomCheckbox = ({ label, onChange, name, value }) => {
  return (
    <FormControlLabel
      control={
        <CustomCheckboxStyle name={name} onChange={onChange} checked={value} />
      }
      label={<span className="text-[11px]">{label}</span>}
    />
  );
};

const Title = ({ children, titleStyle }) => {
  return <div className={`text-xs ${titleStyle}`}>{children}</div>;
};

const AdvanceFilter = ({ onChange, filterKey }) => {
  const filters = useSelector((state) => state.filter.filters || {});
  const [updatedFilters, setFilters] = useState(
    filters?.[filterKey]?.categories_filter_ids || ""
  );
  const filterData = [
    {
      titleStyle: "text-[#F42626] text-center",
      title: "My Previous Filters",
      checkboxes: [
        { label: "Distance from city center 3 km", name: "distance::3000" },
        { label: "Private bathroom", name: "room_facility::38" },
        { label: "★★★★☆", name: "class::4" },
      ],
    },
    {
      titleStyle: "text-[#F6C159] text-center",
      title: "Popular Filters",
      checkboxes: [],
    },
    {
      title: "Property Type",
      checkboxes: [
        { label: "Lodges", name: "property_type::221" },
        { label: "Hostel", name: "property_type::203" },
        { label: "Holiday homes", name: "property_type::220" },
      ],
    },
    {
      title: "Room Facilities",
      checkboxes: [
        { label: "Balcony", name: "room_facility::17" },
        { label: "Sea view", name: "room_facility::81" },
        { label: "Air conditioning", name: "room_facility::11" },
        { label: "TV", name: "room_facility::75" },
        { label: "Coffee/tea maker", name: "room_facility::120" },
      ],
    },
    {
      title: "Meals",
      checkboxes: [
        { label: "Breakfast included", name: "mealplan::breakfast_included" },
        { label: "Self catering", name: "mealplan::999" },
      ],
    },
    {
      title: "Hotel Facilities",
      checkboxes: [
        { name: "facility::107", label: "Free WiFi" },
        { name: "facility::139", label: "Airport shuttle" },
        { name: "facility::4", label: "Pets allowed" },
        { name: "facility::2", label: "Parking" },
        { name: "facility::433", label: "Swimming pool" },
        { name: "facility::11", label: "Fitness centre" },
      ],
    },
    {
      title: "Property rating",
      checkboxes: [
        { label: "1 star", name: "class::1" },
        { label: "2 star", name: "class::2" },
        { label: "3 star", name: "class::3" },
        { label: "4 star", name: "class::4" },
        { label: "5 star", name: "class::5" },
        { label: "Unrated", name: "class::0" },
      ],
    },
    {
      title: "Review Score",
      checkboxes: [
        { label: "Passable: 5+", name: "reviewscorebuckets::50" },
        { label: "Pleasant: 6+", name: "reviewscorebuckets::60" },
        { label: "Good: 7+", name: "reviewscorebuckets::70" },
        { label: "Very good: 8+", name: "reviewscorebuckets::80" },
        { label: "Superb: 9+", name: "reviewscorebuckets::90" },
      ],
    },
    {
      title: "Reservation Policy",
      checkboxes: [
        { label: "Free cancellation", name: "free_cancellation::1" },
      ],
    },
  ];

  function handleChange(e, checked) {
    const name = e.target?.name || "";
    let currentCategories = updatedFilters;
    let categoriesArray = currentCategories ? currentCategories.split(",") : [];
    if (checked) {
      // If checked is true, add the name to the array (if it's not already included)
      if (!categoriesArray.includes(name)) {
        categoriesArray.push(name);
      }
    } else {
      // If checked is false, remove the name from the array
      categoriesArray = categoriesArray.filter((category) => category !== name);
    }

    // Convert the array back into a comma-separated string
    const updatedCategories = categoriesArray.join(",");
    setFilters(updatedCategories);

    // Pass the updated categories_filter_ids back through the onChange handler
    if (onChange) {
      onChange({ categories_filter_ids: updatedCategories });
    }
  }
  const checksValue = updatedFilters.split(",").map((s) => s.trim());
  return (
    <div className="  w-full ">
      <div className="my-3 flex items-center gap-2">
        <Chip
          label="Non smoke"
          icon={<SmokeFree sx={{ fontSize: "18px", color: "black" }} />} // Icon at the start
          onClick={() =>
            handleChange({ target: { name: "facility::16" } }, true)
          }
          size="small" // Sets chip size to small
          sx={{
            cursor: "pointer",
            borderRadius: "4px",
            backgroundColor: updatedFilters.includes("facility::16")
              ? "white"
              : "transparent",
            border: "1px solid white",
            display: "flex",
            justifyContent: "space-between", // Pushes icon to the right
            paddingRight: "8px", // Adjusts padding to ensure space for the icon on the right
            "& .MuiChip-icon": {
              order: 2, // Places the icon at the end
            },
            "& .MuiChip-label": {
              order: 1, // Places the label before the icon
            },
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        />
        <Chip
          label="Any"
          onClick={() =>
            handleChange({ target: { name: "facility::16" } }, false)
          }
          size="small" // Sets chip size to small
          sx={{
            cursor: "pointer",
            borderRadius: "4px",
            backgroundColor: !updatedFilters.includes("facility::16")
              ? "white"
              : "transparent",
            border: "1px solid white",
            display: "flex",
            justifyContent: "space-between", // Pushes icon to the right
            paddingRight: "8px", // Adjusts padding to ensure space for the icon on the right
            "& .MuiChip-icon": {
              order: 2, // Places the icon at the end
            },
            "& .MuiChip-label": {
              order: 1, // Places the label before the icon
            },
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        />
      </div>
      {filterData.map((group, index) => (
        <div key={index} className="mt-4">
          <Title titleStyle={group.titleStyle}>{group.title}</Title>
          {group.checkboxes.map((check, idx) => (
            <CustomCheckbox
              onChange={handleChange}
              key={idx}
              label={check.label}
              name={check.name}
              value={checksValue.includes(check.name)}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default AdvanceFilter;
