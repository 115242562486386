import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { isGreaterDate } from "../../common/Helpers";

const DateRangePicker = ({ initialValue = [null, null], onChange }) => {
  const [startDate, setStartDate] = useState(formatDate(initialValue?.[0]));
  const [endDate, setEndDate] = useState(formatDate(initialValue?.[1]));

  function formatDate(date) {
    return dayjs(date).format("ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)");
  }
  const handleChange = (date, name) => {
    if (name === "startDate") {
      setStartDate(date);
      if (isGreaterDate(date, endDate)) {
        setEndDate(formatDate(dayjs(date).add(1, "day")));
      }
    } else {
      setEndDate(date);
    }
    SetRange(date, name);
  };

  function SetRange(newDate, name) {
    if (onChange) {
      if (name === "startDate") {
        let end = endDate;
        if (isGreaterDate(newDate, end)) {
          end = formatDate(dayjs(newDate).add(1, "day"));
        }
        onChange({ startDate: newDate, endDate: end });
      } else {
        onChange({ startDate: startDate, endDate: newDate });
      }
    }
  }

  return (
    <div className="flex flex-col items-center w-full mb-3">
      <div className="grid grid-cols-2 gap-4 w-full max-w-lg">
        <div className="relative w-full max-w-xs">
          <DatePicker
            selected={startDate}
            onChange={(date) => handleChange(date, "startDate")}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholderText="Check in"
          />
        </div>
        <div className="relative w-full max-w-xs">
          <DatePicker
            selected={endDate}
            onChange={(date) => handleChange(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholderText="Check out"
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
