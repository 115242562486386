import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import InfoCard from "./InfoCard";
import InfoBuyRentCard from "./InfoBuyRentCard";
import ChipContainer from "./ChipContainer";
import ImageGallery from "./ImageGallery";
import { useDispatch, useSelector } from "react-redux";
import BottomMenu from "../BottomMenu/BottomMenu";
import { setActiveListAction } from "../../store/Common/commonActions";

const EmptyContent = () => {
  return (
    <div className="flex justify-center items-center h-full bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE]">
      Please search for a place to load property
    </div>
  );
};
const Swipe = ({ balooDir, isMobileScreen = false }) => {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.map.activeStep);
  const layout = useSelector((state) => state.layout.col);
  const listingDetail = useSelector((state) => state.hotels.listingDetail);
  const swipeScreenActiveListing = useSelector(
    (state) => state.hotels.swipeScreenActiveListing
  );
  const listingMedia = useSelector((state) => state.hotels.listingMedia);
  const listingMediaVideo = useSelector(
    (state) => state.hotels.listingMediaVideo
  );
  const nearbyPlace = useSelector((state) => state.hotels.nearbyPlace);
  const hotels = useSelector((state) => state.hotels);
  const [swipeList, setList] = useState(hotels?.hotels?.features || []);
  const [activeItem, setActive] = useState(null);

  useEffect(() => {
    setList(hotels?.hotels?.features || []);
  }, [hotels]);

  useEffect(() => {
    if (activeStep) {
      renderDetail();
    }
  }, [activeStep]);

  function renderDetail() {
    const listings = hotels?.hotels?.features;
    let activeListing = null;
    if (listings?.length > 0) {
      activeListing = activeStep
        ? listings.find((item) => item.id === activeStep)
        : listings?.[0];
    }
    if (!activeListing) {
      if (
        listingDetail?.listingType === "rent" ||
        listingDetail?.listingType === "buy"
      ) {
        activeListing = {
          properties: {
            ...listingDetail,
            accommodationType: listingDetail?.listingType,
            latitude: listingDetail?.ubication?.latitude,
            longitude: listingDetail?.ubication?.longitude,
          },
        };
      } else if (listingDetail?.listingType === "monthlyStay") {
        activeListing = {
          properties: {
            ...listingDetail,
            accommodationType: listingDetail?.listingType,
            listingKey: listingDetail?.listingType,
            thumbnail: listingDetail?.imageUrl,
            id: swipeScreenActiveListing?.properties?.id,
            propertyName: swipeScreenActiveListing?.properties?.name,
            price: swipeScreenActiveListing?.properties?.price,
            review_score: swipeScreenActiveListing?.properties?.review_score,
            badge: swipeScreenActiveListing?.properties?.review_word,
            latitude: listingDetail?.listingLat,
            longitude: listingDetail?.listingLng,
          },
        };
      } else {
        activeListing = {
          properties: {
            ...listingDetail,
            accommodationType: listingDetail?.listingType,
            listingKey: listingDetail?.listingType,
            id: listingDetail?.hotel_id,
            review_score: swipeScreenActiveListing?.properties?.review_score,
            badge: swipeScreenActiveListing?.properties?.review_word,
          },
        };
      }
    }
    setActive(activeListing);
    dispatch(setActiveListAction(activeListing));
  }

  const filterStyle = {
    rent: { bg: "#94DC20" },
    Hotel: { bg: "#5397FD" },
    Shortterm: { bg: "#4a90e2" },
    monthlyStay: { bg: "#FF5139" },
    buy: { bg: "#DC8520" },
  };
  const isEmpty = (!swipeList || swipeList?.length <= 0) && !activeItem;
  return (
    <div className="h-full">
      {/* Header: Tabs for Hotel Details, My Locations, The Area */}
      <div className={`${isEmpty ? "h-full" : "sm:h-[17%] h-[20%]"} `}>
        {isEmpty ? (
          <EmptyContent />
        ) : (
          <div className="h-full  w-full">
            <div className="w-full h-full">
              {activeItem?.properties?.accommodationType === "rent" ||
              activeItem?.properties?.accommodationType === "buy" ? (
                <InfoBuyRentCard
                  details={activeItem || swipeList?.[0]}
                  listingDetail={listingDetail}
                />
              ) : (
                <InfoCard
                  details={activeItem || swipeList?.[0]}
                  listingDetail={listingDetail}
                  listingMedia={listingMedia}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {!isEmpty && (
        <div className="relative sm:h-[76.0%] h-[71.3%]">
          {/* Main Image Gallery (taking the remaining height) */}
          {(activeItem || swipeList?.[0]) && (
            <div className=" h-full">
              <ImageGallery
                swipeList={swipeList}
                balooDir={balooDir}
                details={activeItem || swipeList?.[0]}
                isMobileScreen={isMobileScreen}
                listingDetail={listingDetail}
                listingMedia={listingMedia}
                listingMediaVideo={listingMediaVideo}
                nearbyPlace={nearbyPlace}
                layout={layout}
              />
            </div>
          )}

          {/* Chips Section */}
          {(activeItem || swipeList?.[0]) && (
            <ChipContainer
              details={activeItem || swipeList?.[0]}
              listingDetail={listingDetail}
            />
          )}
        </div>
      )}

      {/* Footer: Bottom Menu */}
      {!isEmpty && (
        <Box sx={{ boxShadow: 1 }} className="sm:h-[1%] h-[5%]">
          <BottomMenu
            isMobileScreen={isMobileScreen}
            activeItem={activeItem}
            filterStyle={filterStyle}
            listingDetail={listingDetail}
          />
        </Box>
      )}
    </div>
  );
};

export default Swipe;
