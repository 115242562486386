import React, { useState, useEffect } from "react";
import { Box, Typography, Slider } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiSlider from "../MuiSlider";
import { useSelector } from "react-redux";

const WhiteSlider = styled(Slider)({
  color: "#fff", // Set slider color to white
  "& .MuiSlider-thumb": {
    borderRadius: "50%", // Optional: round thumb
  },
  "& .MuiSlider-track": {
    borderColor: "#fff", // Track color
  },
  "& .MuiSlider-rail": {
    color: "#fff", // Rail color (unfilled portion)
  },
});

const BudgetRange = ({
  initialValue = [50, 200],
  onChange,
  title,
  minKey,
  maxKey,
  minValue,
  maxValue,
  filterKey,
}) => {
  const filters = useSelector((state) => state.filter.filters || {});
  const [value, setValue] = useState(initialValue);
  const [updatedFilters, setFilters] = useState(
    filters?.[filterKey]?.categories_filter_ids || ""
  );

  useEffect(() => {
    setValue(initialValue); // Initialize with correct state
  }, [initialValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange({ [minKey]: newValue[0], [maxKey]: newValue[1] });
    }
  };

  function operateFilter(e, value) {
    let currentCategories = updatedFilters;
    let categoriesArray = currentCategories ? currentCategories.split(",") : [];
    categoriesArray = categoriesArray.filter(
      (category) => !category.includes("price::USD-")
    );
    if (value[0] > minValue || value[1] < maxValue) {
      categoriesArray.push(`price::USD-${value[0]}-${value[1]}`);
    }
    const updatedCategories = categoriesArray.join(",");
    setFilters(updatedCategories);
    if (onChange) {
      onChange({ categories_filter_ids: updatedCategories });
    }
  }

  return (
    <Box border={1} borderColor="grey.400" borderRadius="8px" padding="10px">
      <div className="text-xs text-white">{title}</div>
      <MuiSlider
        value={value}
        onChange={handleChange}
        onChangeCommitted={operateFilter}
        min={minValue || 0}
        max={maxValue || 250}
        displayLabel="off"
      />
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="body2" color="white">
          ${value[0]}
        </Typography>
        <Typography variant="body2" color="white">
          ${value[1] === maxValue ? `${value[1]}+` : value[1]}
        </Typography>
      </Box>
    </Box>
  );
};

export default BudgetRange;
