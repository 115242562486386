import React, { useEffect, useState } from "react";
import drawIcon from "../../../assets/icons/svg/draw-icon.svg";
import radiusIcon from "../../../assets/icons/svg/radius.svg";
import aiIcon from "../../../assets/icons/svg/ai.svg";

const Tools = ({ onDrawChange, onRadiusChange, clear }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isRadiusChecked, setIsRadiusChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(!isChecked);
    onDrawChange(!isChecked);
  };

  const handleRadiusCheckboxChange = (event) => {
    setIsRadiusChecked(!isRadiusChecked);
    onRadiusChange(!isRadiusChecked);
  };

  useEffect(() => {
    if (!clear) setIsChecked(false);
  }, [clear]);

  return (
    <div className="z-10 top-[80px] right-2 absolute">
      <div
        className=" bg-[#528da9] rounded-full p-2 text-center cursor-pointer mb-2 w-[25px] h-[25px]"
        onClick={handleCheckboxChange}
      >
        <img alt="draw" src={drawIcon} />
      </div>
      <div className=" bg-[#af5aa9] rounded-full p-2 text-center cursor-pointer mb-2 w-[25px] h-[25px]">
        <img alt="ai" src={aiIcon} />
      </div>
      <div
        className=" bg-[#4acc5a] rounded-full p-2 text-center cursor-pointer mb-2 w-[25px] h-[25px]"
        onClick={handleRadiusCheckboxChange}
      >
        <img alt="radius" src={radiusIcon} />
      </div>
    </div>
  );
};

export default Tools;
