import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  commonStore: {
    activeScreen: "search",
  },
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setCommonStore: (state, action) => {
      state.commonStore = { ...state.commonStore, ...action.payload };
    },
  },
});
export const { setCommonStore } = commonSlice.actions;
export default commonSlice.reducer;
