import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

// Styled component using Material UI's styled and Tailwind
const StyledSelect = styled(Select)(() => ({
  backgroundColor: "#ffffff", // Matches the green background from the image
  width: "100%", // Adjust width as per your need
  "& .MuiSelect-select": {
    padding: "5px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
}));

const Dropdown = ({ options, onChange, defaultValue }) => {
  const [option, setOption] = React.useState(defaultValue || "any");

  const handleChange = (event) => {
    setOption(event.target.value);
    if (onChange) {
      onChange({ target: { name: "propertyType" } }, false, event.target.value);
    }
  };

  return (
    <div className="flex justify-center items-center">
      <StyledSelect
        value={option}
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value="any">
          <em>Any</em>
        </MenuItem>
        {options.map((opt, index) => (
          <MenuItem key={index} value={opt.name}>
            {opt.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </div>
  );
};

export default Dropdown;
