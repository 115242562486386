import React, { useCallback, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useMapsLibrary, useMap } from "@vis.gl/react-google-maps";
import { Close } from "@mui/icons-material";

const MapAutoSearch = ({
  map,
  onSelectValue,
  isDisableSearchIcon,
  onHandleChange,
  label,
  value,
}) => {
  const places = useMapsLibrary("places");
  const [state, setState] = useState({
    searchValue: value,
    latitude: "",
    longitude: "",
  });
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isClose, setClose] = useState(false);
  const [sessionToken, setSessionToken] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [isPlaceSelected, setIsPlaceSelected] = useState(false);
  const [placesService, setPlacesService] = useState(null);

  useEffect(() => {
    if (!places || !map) {
      return;
    }
    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => {
      setAutocompleteService(null);
      setPlacesService(null);
    };
  }, [places, map]);

  const handleChange = (e) => {
    const value = e.target.value;
    setClose(false);
    onHandleChange && onHandleChange(value);
    setIsPlaceSelected(false);

    if (value) {
      fetchPredictions(value);
    } else {
      setPredictions([]); // Clear predictions if input is empty
    }
  };

  const fetchPredictions = useCallback(
    (input) => {
      if (!autocompleteService) {
        return;
      }
      setLoading(true);
      autocompleteService.getPlacePredictions(
        { input, sessionToken },
        (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPredictions(results || []);
          } else {
            setPredictions([]);
          }
          setLoading(false);
        }
      );
    },
    [autocompleteService, sessionToken]
  );
  const handleSelect = (prediction) => {
    if (!placesService) return;
    setLoading(true);
    const request = {
      placeId: prediction.place_id,
      fields: ["geometry", "name", "formatted_address", "types", "photos"],
      sessionToken,
    };

    placesService.getDetails(request, (place) => {
      if (place) {
        const { north, east } = place.geometry.viewport.toJSON();
        const selectedValue = {
          searchValue: place.formatted_address || "",
          latitude: north,
          longitude: east,
          image: place?.photos?.[0].getUrl(),
          name: place?.name,
        };
        setState(selectedValue);
        onSelectValue(selectedValue);
        setPredictions([]); // Clear predictions when a place is selected
        setIsPlaceSelected(true);
        setSessionToken(new places.AutocompleteSessionToken());
      }
      setLoading(false);
    });
  };

  function closePredections() {
    setClose(true);
  }

  return (
    <div className="w-full">
      <div className="flex items-center justify-center">
        {!isDisableSearchIcon && (
          <div className="bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE] text-[#fff] py-[4.7px] px-1 flex items-center rounded-l-[10px]">
            <SearchIcon />
          </div>
        )}
        <input
          type="text"
          placeholder={label || ""}
          value={value}
          onChange={handleChange}
          className={`w-full rounded-10 shadow-lg bg-[#fff] p-2 border-none ${
            isDisableSearchIcon ? "" : "rounded-l-none"
          }`}
        />
      </div>
      <div>
        {!isPlaceSelected && value && predictions.length > 0 && !isClose && (
          <ul className="autocomplete-suggestions">
            {isDisableSearchIcon && (
              <li
                key={"close"}
                className="text-right px-2"
                onClick={closePredections}
              >
                <Close className="!text-[14px]" />
              </li>
            )}
            {predictions.map((prediction) => (
              <li
                key={prediction.place_id}
                onClick={() => handleSelect(prediction)}
                className="autocomplete-suggestion text-xs"
              >
                {prediction.description}
              </li>
            ))}
          </ul>
        )}
        {loading && <div className="loading-spinner">Loading...</div>}
      </div>
    </div>
  );
};

export default MapAutoSearch;
