import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Tab,
  Tabs,
  Grid,
  Typography,
  Card,
  CardMedia,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useMap } from "@vis.gl/react-google-maps";
import { useDispatch } from "react-redux";
import { setMapIDAction } from "../../store/Map/mapActions";
import { setCoordinates } from "../../store/Filter/filterReducers";
import SearchInput from "../SearchInput";
import { placeTypes } from "./places";

import basicMapIcon from "../../assets/icons/svg/basic.png";
import setaliteMapIcon from "../../assets/icons/svg/setalite.png";
import threeDMapIcon from "../../assets/icons/svg/3d.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function BottomSheetTabs({ open, onClose, onPlacesUpdate, isShowMapType }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const [activePlaceType, setActivePlaceType] = useState(""); // Track active place types
  const [position, setPosition] = useState({});
  const [mapLayers, setMapLayers] = useState({
    metro: false,
    cityCenter: false,
    neighborhoods: false,
  }); // State to track the map layers
  const sheetRef = useRef(null);
  const map = useMap();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const togglePlace = (placeType) => {
    if (activePlaceType?.type === placeType?.type) {
      setActivePlaceType(undefined);
    } else {
      setActivePlaceType(placeType);
    }
  };

  const toggleMyLocation = (position) => {
    setPosition(position);

    const { lat, lng } = position;
    dispatch(setCoordinates({ lat, lng }));
    map.setCenter(position);
  };

  useEffect(() => {
    if (onPlacesUpdate) {
      onPlacesUpdate(activePlaceType);
    } else {
      onPlacesUpdate(null);
    }
  }, [activePlaceType, onPlacesUpdate]);

  const handleLayerToggle = (event) => {
    const { name, checked } = event.target;
    setMapLayers((prev) => ({
      ...prev,
      [name]: checked,
    }));

    if (name === "metro" && checked) {
      // Turn on the Metro | Bikes | Roads layer
      map.setMapTypeId("roadmap"); // Replace with your custom map layer ID
      setMapIDAction(dispatch, "74514ea1fc7a008a");
    } else if (name === "metro" && !checked) {
      // Turn off the Metro | Bikes | Roads layer
      map.setMapTypeId("roadmap"); // Switch back to the default map type
      setMapIDAction(dispatch, "cf7d4516f98d5329");
    }

    // Add similar conditions for other layers if necessary
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (sheetRef.current && !sheetRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  const handleMapType = (type) => {
    if (type === "Basic") {
      map.setMapTypeId("roadmap");
      setMapIDAction(dispatch, "map");
    }
    if (type === "Satellite") {
      map.setMapTypeId("satellite");
      setMapIDAction(dispatch, "cf7d4516f98d5329");
    }
    if (type === "3D") {
      setMapIDAction(dispatch, "cf7d4516f98d5329");
      map.setMapTypeId("satellite");
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleSearch = (value) => {
    setActivePlaceType(value);
    // Implement your search logic here
  };
  const mapTypes = [
    { type: "Basic", icon: basicMapIcon },
    { type: "Satellite", icon: setaliteMapIcon },
    { type: "3D", icon: threeDMapIcon },
  ];
  return (
    <React.Fragment>
      {isShowMapType && (
        <div className="absolute bottom-[83px] flex gap-2 justify-end items-center w-[85%]">
          {mapTypes.map((map) => (
            <div
              onClick={() => handleMapType(map.type)}
              key={map.type}
              className=" rounded-full p-2 text-center cursor-pointer mb-2 w-[25px] h-[25px] flex  justify-center items-center"
            >
              <img alt={map.type} src={map.icon} />
            </div>
          ))}
        </div>
      )}
      {open && (
        <Box
          ref={sheetRef}
          sx={{
            position: "absolute",
            bottom: 30,
            left: 0,
            right: 0,
            zIndex: 1000,
            bgcolor: "#F9F9F9",
            borderRadius: "16px 16px 0 0",
            boxShadow: 24,
            maxWidth: "100%",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            centered
            sx={{
              minHeight: "40px",
              "& .MuiTab-root": {
                color: "#4A90E2",
                fontSize: "0.6rem",
                minHeight: "40px",
                fontWeight: "bold",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#4A90E2",
              },
            }}
          >
            <Tab label="My Locations" />
            <Tab label="Places" />
            <Tab label="Turn on/off" />
          </Tabs>

          <TabPanel value={value} index={0}>
            <div className="flex flex-row gap-4 overflow-x-scroll items-center justify-between p-5">
              {[
                {
                  name: "Office",
                  position: { lat: 38.717684, lng: -9.139813 },
                },
                { name: "Mom", position: { lat: 38.728623, lng: -9.150019 } },
                {
                  name: "Eiffel",
                  position: { lat: 38.721899, lng: -9.135432 },
                },
                {
                  name: "My beach",
                  position: { lat: 38.711921, lng: -9.13872 },
                },
                {
                  name: "My Uni",
                  position: { lat: 38.725468, lng: -9.147945 },
                },
              ].map((location, index) => (
                <div
                  className="flex flex-col gap-3 self-center overflow-x-scroll cursor-pointer"
                  key={index}
                >
                  <Card
                    onClick={() => toggleMyLocation(location.position)}
                    sx={{
                      borderRadius: "50%",
                      width: 60,
                      height: 60,
                      overflow: "hidden",
                      textAlign: "center",
                      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: "100%",
                        height: "auto",
                      }}
                      image={`https://picsum.photos/200?random=${index + 1}`}
                      alt={location.name}
                    />
                  </Card>
                  <Typography
                    color={
                      position === location.position ? "#4A90E2" : "#000000"
                    }
                    fontWeight={"medium"}
                    className="text-center"
                    variant="caption"
                  >
                    {location.name}
                  </Typography>
                </div>
              ))}
            </div>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <div className="flex flex-col flex-1 justify-center items-center">
              <SearchInput
                value={search}
                onChange={handleSearchChange}
                onClear={handleClearSearch}
                onSearch={handleSearch}
              />
            </div>
            <div className="flex flex-row gap-4 overflow-x-scroll items-start justify-between p-5">
              {placeTypes.map((place, index) => (
                <div
                  className="flex flex-col items-center gap-3 cursor-pointer"
                  key={index}
                  onClick={() => togglePlace(place)}
                >
                  <Card
                    sx={{
                      borderRadius: "50%",
                      width: 60,
                      height: 60,
                      overflow: "hidden",
                      textAlign: "center",
                      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {place?.iconType === "custom" ? (
                      <img
                        width={40}
                        height={40}
                        src={place.icon}
                        alt="airport"
                      />
                    ) : (
                      <i className={place.icon}></i>
                    )}
                  </Card>
                  <Typography
                    color={
                      activePlaceType?.type === place.type
                        ? "#4A90E2"
                        : "#000000"
                    }
                    fontWeight={"medium"}
                    className="text-center"
                    variant="caption"
                  >
                    {place.label}
                  </Typography>
                </div>
              ))}
            </div>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Grid container spacing={1}>
              {[
                { label: "Metro", value: "metro" },
                { label: "Bikes", value: "bikes" },
                { label: "Roads", value: "roads" },
              ].map((option, index) => (
                <div className="flex flex-row justify-between" key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={option.value}
                        checked={mapLayers[option.value]}
                        onChange={handleLayerToggle}
                        sx={{
                          color: "#4A90E2",
                          "&.Mui-checked": {
                            color: "#4A90E2",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        fontWeight={"medium"}
                        sx={{
                          color: "#4A4A4A",
                          "&.Mui-checked + span": {
                            color: "#4A90E2",
                          },
                        }}
                      >
                        {option.label}
                      </Typography>
                    }
                    sx={{ margin: 0, padding: "4px 0" }}
                  />
                </div>
              ))}
            </Grid>
          </TabPanel>
        </Box>
      )}
    </React.Fragment>
  );
}

export default BottomSheetTabs;
