import React from "react";
import { Chip } from "@mui/material";
import { rentBuyTags } from "./facilityMapping";

const ChipContainer = ({ details, listingDetail }) => {
  const { accommodationType } = details?.properties || {};
  const rentBuyFac = listingDetail?.moreCharacteristics
    ? Object.keys(listingDetail?.moreCharacteristics)
    : [];
  return (
    <div className="overflow-x-auto hide-scrollbar w-full absolute bottom-[91px]">
      <div className="flex ">
        {accommodationType === "rent" || accommodationType === "sell"
          ? (rentBuyFac || []).map((key, index) => {
              if (
                !rentBuyTags.includes(key) ||
                (typeof listingDetail?.moreCharacteristics?.[key] ===
                  "boolean" &&
                  !listingDetail?.moreCharacteristics?.[key])
              ) {
                return;
              }
              if (
                typeof listingDetail?.moreCharacteristics?.[key] === "boolean"
              ) {
                return (
                  <Chip
                    key={index}
                    label={`${key}`}
                    sx={{ margin: "0 5px" }}
                    className="bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE] !text-[#fff]"
                  />
                );
              }
              return (
                <Chip
                  key={index}
                  label={`${key} ${listingDetail?.moreCharacteristics?.[key]}`}
                  sx={{ margin: "0 5px" }}
                  className="bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE] !text-[#fff]"
                />
              );
            })
          : (listingDetail?.facility || []).map((chip, index) => {
              if (!chip?.title) {
                return;
              }
              return (
                <Chip
                  key={index}
                  label={chip?.title || ""}
                  sx={{ margin: "0 5px" }}
                  className="bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE] !text-[#fff]"
                />
              );
            })}
      </div>
    </div>
  );
};
export default ChipContainer;
