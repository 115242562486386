import {
  getUserFavoriteListUrl,
  addFavoriteUrl,
  blackListUrl,
  deleteUserFavoriteItemUrl,
  pinFavoriteItemUrl,
  saveFavoriteItemNotesUrl,
  getUserBlackListUrl,
} from "@/utils/apis/user";
import httpClient from "@/utils/apis/httpClient";
import {
  setUserStore,
  setRequestStatus,
  setBlackList,
} from "@/store/User/userReducers";
import {
  addMyLocationUrl,
  deleteLocationUrl,
  getUserLocationUrl,
  updateLocationUrl,
} from "../../utils/apis/user";

export const getBlackList = (id) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.get(`${getUserBlackListUrl}/${id}`);
    dispatch(setBlackList({ blackList: response?.data?.blackList }));
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    dispatch(setRequestStatus({ isLoading: false }));
  }
};

export const getFavoriteList = (id) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.get(`${getUserFavoriteListUrl}/${id}`);
    dispatch(setUserStore({ favoriteList: response?.data?.favoriteList }));
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    dispatch(setRequestStatus({ isLoading: false }));
  }
};

export const addToBlackList = (params) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.post(blackListUrl, { ...params });
    dispatch(setBlackList({ blackList: response?.data?.blackList }));
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    dispatch(setRequestStatus({ isLoading: false }));
  }
};

export const addToFavoriteList = (params) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.post(addFavoriteUrl, { ...params });
    dispatch(setUserStore({ favoriteList: response?.data?.favoriteList }));
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    dispatch(setRequestStatus({ isLoading: false }));
  }
};

export const saveFavoriteItemNotes = (params) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.post(saveFavoriteItemNotesUrl, params);
    console.log(response);
    // dispatch(setUserStore({ favoriteList: response?.data?.favoriteList }));
    // dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    dispatch(setRequestStatus({ isLoading: false }));
  }
};

export const pinFavoriteItem = (userId, listingId) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.get(
      `${pinFavoriteItemUrl}/${userId}/${listingId}`
    );
    dispatch(setUserStore({ favoriteList: response?.data?.favoriteList }));
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    dispatch(setRequestStatus({ isLoading: false }));
  }
};

export const deleteFavoriteItem = (userId, listingId) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.delete(
      `${deleteUserFavoriteItemUrl}/${userId}/${listingId}`
    );
    dispatch(setUserStore({ favoriteList: response?.data?.favoriteList }));
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    dispatch(setRequestStatus({ isLoading: false }));
  }
};

export const addMyLocationAction = (payload) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.post(`${addMyLocationUrl}`, payload);
    dispatch(setUserStore({ myLocations: response?.data?.data }));
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    dispatch(setRequestStatus({ isLoading: false }));
  }
};
export const getUserLocationsAction = (userId) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.get(`${getUserLocationUrl}/${userId}`);
    dispatch(setUserStore({ myLocations: response?.data }));
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    dispatch(setRequestStatus({ isLoading: false }));
  }
};
export const deleteMylocationAction =
  (userId, locationId) => async (dispatch) => {
    dispatch(setRequestStatus({ isLoading: true }));
    try {
      let response = await httpClient.delete(
        `${deleteLocationUrl}/${userId}/${locationId}`
      );
      dispatch(setUserStore({ myLocations: response?.data?.data }));
      dispatch(setRequestStatus({ isLoading: false }));
    } catch (error) {
      dispatch(setRequestStatus({ isLoading: false }));
    }
  };
export const updateMylocationAction =
  (userId, locationId, payload) => async (dispatch) => {
    dispatch(setRequestStatus({ isLoading: true }));
    try {
      let response = await httpClient.put(
        `${updateLocationUrl}/${userId}/${locationId}`,
        payload
      );
      dispatch(setUserStore({ myLocations: response?.data?.data }));
      dispatch(setRequestStatus({ isLoading: false }));
    } catch (error) {
      dispatch(setRequestStatus({ isLoading: false }));
    }
  };
