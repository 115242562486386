import React, { useState } from "react";

export default function MuiButtonGroup({ btns, onSelect, defaultValue }) {
  const [active, setActive] = useState(defaultValue || "any");
  const onClick = (value) => () => {
    setActive(value);
    if (onSelect) {
      onSelect(value);
    }
  };
  return (
    <div className="flex gap-[1px]">
      {btns.map((item, index) => (
        <button
          key={`${index}`}
          className={`px-2 py-1 border-0 rounded-sm shadow-sm cursor-pointer ${
            active === item.value ? "bg-primary !text-[#fff]" : "bg-whitet"
          }`}
          onClick={onClick(item.value)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
}
