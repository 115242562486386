import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Header from "../components/Header";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { Fragment } from "react";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  if (loading) {
    return <div>loading...</div>;
  }

  return isAuthenticated ? (
    <Fragment>
      <Header />
      <Content>{children}</Content>
    </Fragment>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
