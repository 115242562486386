import React, { useEffect, useState } from "react";

const useWikipediaInfo = (city) => {
  const [info, setInfo] = useState(null);

  useEffect(() => {
    const fetchWikipediaInfo = async () => {
      const response = await fetch(
        `https://en.wikipedia.org/w/api.php?action=query&format=json&prop=extracts|pageprops|info&titles=${city}&exintro=1&explaintext=1&inprop=url&origin=*`
      );

      const data = await response.json();
      const page = data.query.pages;
      const pageId = Object.keys(page)[0];
      if (pageId && page[pageId]) {
        setInfo({
          title: page[pageId].title,
          extract: page[pageId].extract,
          url: page[pageId].fullurl,
        });
      }
    };

    fetchWikipediaInfo();
  }, [city]);

  return info;
};

export default useWikipediaInfo;
