import React, { useEffect } from "react";
import { useState } from "react";
import startIcon from "../../assets/icons/svg/star.svg";
import birdIcon from "../../assets/icons/svg/bird-eye.svg";
import streetIcon from "../../assets/icons/svg/street.svg";
import placesIcon from "../../assets/icons/svg/place-360.svg";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const SwipeTabs = ({ onTabChange, isMobileScreen }) => {
  const [value, setValue] = useState("my-loc");

  const onChangeTab = (activeKey) => {
    setValue(activeKey);
  };
  const tabs = [
    { key: "my-loc", label: "My Location", icon: <LocationOnIcon /> },
    { key: "star", label: "Star", icon: startIcon },
    { key: "bird-eye", label: "Bird’s-Eye", icon: birdIcon },
    { key: "street", label: "Street View", icon: streetIcon },
    { key: "places", label: "Places 360", icon: placesIcon },
  ];

  useEffect(() => {
    onTabChange(value);
  }, [value]);

  return (
    <div className="w-full flex flex-none hide-scrollbar shadow-2xl">
      <div
        className={`flex w-full flex-1 ${
          isMobileScreen ? "bg-primary" : "bg-[#fff]"
        } overflow-hidden hide-scrollbar shadow-2xl`}
      >
        <div
          className={`flex bg-[#fff] w-full hide-scrollbar overflow-x-scroll items-center ${
            isMobileScreen ? "rounded-full rounded-b-none" : ""
          } shadow-2xl`}
        >
          {tabs.map((tab) => (
            <div
              onClick={() => onChangeTab(tab.key)}
              key={tab.key}
              className={`text-black text-center ${
                tab.key === value ? "font-bold" : ""
              } px-4 py-2 hover:text-blue-500 min-w-max cursor-pointer`}
            >
              {tab.key === "my-loc" ? (
                tab.icon
              ) : (
                <img src={tab.icon} alt={tab.key} />
              )}
              <div>{tab.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SwipeTabs;
