import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Popover } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { styled } from "@mui/system";

const StyledButton = styled(Button)`
  min-width: 36px;
  min-height: 36px;
  border-radius: 5px;
  margin: 0 8px;
`;

const OccupancyForm = ({ initialOccupancy, onChange }) => {
  const [occupancy, setOccupancy] = useState(initialOccupancy);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    setOccupancy(initialOccupancy); // Initialize with correct state
  }, [initialOccupancy]);

  const handleIncrement = (type) => {
    const newOccupancy = { ...occupancy, [type]: occupancy[type] + 1 };
    setOccupancy(newOccupancy);
  };

  const handleDecrement = (type) => {
    if (occupancy[type] > 0) {
      const newOccupancy = { ...occupancy, [type]: occupancy[type] - 1 };
      setOccupancy(newOccupancy);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onChange) {
      onChange(occupancy); // Notify parent component of the final changes
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "occupancy-popover" : undefined;

  return (
    <div className="w-full mb-3">
      <TextField
        variant="outlined"
        className="w-full cursor-pointer rounded-[5px] custom-border  !text-[#fff]"
        value={`${occupancy.adults} adults · ${occupancy.children} children · ${occupancy.rooms} rooms`}
        InputProps={{
          startAdornment: <PersonIcon className="mr-2" />,
          className: "text-gray-700 !text-[#fff] ",
        }}
        onClick={handleClick}
        ref={inputRef}
        readOnly
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: inputRef.current ? inputRef.current.offsetWidth : undefined,
          },
        }}
      >
        <div className="p-4">
          {["adults", "children", "rooms"].map((type) => (
            <div key={type} className="flex items-center mb-2">
              <label className="w-24 capitalize">{type}</label>
              <StyledButton
                variant="outlined"
                color="inherit"
                onClick={() => handleDecrement(type)}
              >
                -
              </StyledButton>
              <span className="mx-2">{occupancy[type]}</span>
              <StyledButton
                variant="outlined"
                color="inherit"
                onClick={() => handleIncrement(type)}
              >
                +
              </StyledButton>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default OccupancyForm;
