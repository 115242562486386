import React, { useState } from "react";
import { Typography, Box, FormControlLabel, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import MuiSlider from "../../MuiSlider";
import {
  airbnbAmenities,
  airbnbPropertyTypes,
  airbnbTypeOfPlace,
} from "../../../common/utils/airbnbFilters";

const CustomCheckboxStyle = styled(Checkbox)(({ theme }) => ({
  color: "#fff", // Default color for the border and checkmark
  "&.Mui-checked": {
    color: "#fff", // Color when checked
  },
}));

const CustomCheckbox = ({ label, onChange, name, value }) => {
  return (
    <FormControlLabel
      control={
        <CustomCheckboxStyle name={name} onChange={onChange} checked={value} />
      }
      label={<span className="text-[11px]">{label}</span>}
    />
  );
};

const Title = ({ children, titleStyle }) => {
  return <div className={`text-xs ${titleStyle}`}>{children}</div>;
};

const MonthlyFilters = ({ onChange, filterKey }) => {
  const filters = useSelector((state) => state.filter.filters || {});
  const [updatedFilters, setFilters] = useState(filters?.[filterKey] || "");
  const filterData = [
    {
      title: "Minimum Bedrooms",
      key: "minBedrooms",
    },
    {
      title: "Minimum Beds",
      key: "minBeds",
    },
    {
      title: "Minimum Bathrooms",
      key: "minBathrooms",
    },
  ];
  const checkboxList = [
    {
      title: "Property type",
      key: "property_type",
      checkboxes: airbnbPropertyTypes,
    },
    {
      title: "Amenities",
      key: "amenities",
      checkboxes: airbnbAmenities,
    },
    {
      title: "Type of place",
      key: "type_of_place",
      checkboxes: airbnbTypeOfPlace,
    },
  ];

  function handleChange(e, checked, key) {
    const name = e.target?.name || "";
    let currentFilters = updatedFilters?.[key] || [];
    if (checked) {
      currentFilters.push(name);
    } else {
      currentFilters = currentFilters.filter((c) => c !== name);
    }
    const changedValues = { ...updatedFilters, [key]: currentFilters };
    setFilters(changedValues);
    if (onChange) {
      onChange(changedValues);
    }
  }
  function handleChangeMin(e, value, key) {
    const changedValues = { ...updatedFilters, [key]: value };
    setFilters(changedValues);
    if (onChange) {
      onChange(changedValues);
    }
  }

  return (
    <div className="  w-full ">
      {filterData.map((group, index) => {
        return (
          <Box
            key={`${index}`}
            border={1}
            borderColor="grey.400"
            borderRadius="8px"
            padding="4px"
            className={"mt-2"}
          >
            <div className="text-xs text-white">{group.title}</div>
            <MuiSlider
              value={updatedFilters?.[group.key] || 0}
              onChange={(e, value) => handleChangeMin(e, value, group.key)}
              min={0}
              max={10}
              displayLabel="off"
            />
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography variant="body2" color="white">
                {updatedFilters?.[group.key] > 0
                  ? `${updatedFilters?.[group.key]}+`
                  : 0}
              </Typography>
            </Box>
          </Box>
        );
      })}
      {checkboxList.map((item, index) => (
        <div key={index} className="mt-4">
          <Title>{item.title}</Title>
          {item.checkboxes.map((check) => (
            <CustomCheckbox
              onChange={(e, checked) => handleChange(e, checked, item.key)}
              key={check.id}
              label={check.title}
              name={check.title}
              value={
                updatedFilters?.[item.key]?.length > 0
                  ? updatedFilters[item.key].includes(check.title)
                  : false
              }
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default MonthlyFilters;
