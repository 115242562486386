import React, { useState } from "react";
import { Checkbox, FormControlLabel, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import MuiButtonGroup from "../../MuiButtonGroup/MuiButtonGroup";
import Dropdown from "../../MuiSelector/MuiSelector";
import MuiSlider from "../../MuiSlider";

const CustomCheckboxStyle = styled(Checkbox)(({ theme }) => ({
  color: "#fff", // Default color for the border and checkmark
  "&.Mui-checked": {
    color: "#fff", // Color when checked
  },
}));
const CustomCheckbox = ({ label, onChange, name, value }) => {
  return (
    <FormControlLabel
      control={
        <CustomCheckboxStyle name={name} onChange={onChange} checked={value} />
      }
      label={<span className="text-[11px]">{label}</span>}
    />
  );
};

const Title = ({ children, titleStyle }) => {
  return <div className={`text-xs ${titleStyle}`}>{children}</div>;
};

const RentBuyAdvanceFilter = ({ onChange, filterKey, minPrice, maxPrice }) => {
  const filters = useSelector((state) => state.filter.filters || {});
  const [updatedFilters, setFilters] = useState(filters?.[filterKey] || "");
  console.log("filtersssss", updatedFilters);
  const homeTypes = [
    { label: "Houses", name: "homes" },
    { label: "Apartments", name: "bedrooms" },
    { label: "New Homes", name: "newDevelopments" },
  ];
  const filterData = [
    {
      title: "budget",
    },
    {
      title: "size",
    },
    {
      title: "Floor",
      dataKey: "floorHeights",
      checkboxes: [
        { label: "Top floor", name: "topFloor" },
        { label: "Middle floors", name: "intermediateFloor" },
        { label: "Ground floor", name: "groundFloor" },
      ],
    },
    {
      title: "Popular Filters",
      checkboxes: [
        { label: "Private bathroom", name: "privateToilet" },
        { label: "Air condition", name: "airConditioning" },
        { label: "Gay partners", name: "gayPartners" },
        { label: "Has house keeper", name: "hasHouseKeeper" },
        { label: "Elevator", name: "elevator" },
        { label: "Terrace", name: "terrace" },
        { label: "Swimming pool", name: "swimmingPool" },
        { label: "Garden", name: "garden" },
        { label: "Luxury", name: "luxury" },
        { label: "Builtin wardrobes", name: "builtinWardrobes" },
        { label: "Store room", name: "storeRoom" },
        { label: "Children allowed", name: "childrenAllowed" },
      ],
    },
  ];

  function handleChange(e, checked, value) {
    const name = e.target?.name || "";
    let currentFilters = updatedFilters;
    if (value) {
      if (name === "propertyType" && value === "any") {
        delete currentFilters?.[name];
      } else {
        currentFilters = { ...currentFilters, [name]: value };
      }
    } else {
      if (!checked) {
        if (name in currentFilters) {
          delete currentFilters[name];
        }
      } else {
        currentFilters = { ...currentFilters, [name]: checked };
      }
    }
    setFilters(currentFilters);
    if (onChange) {
      onChange(currentFilters);
    }
  }
  function handleChangeFloor(e, checked, value) {
    const name = e.target?.name || "";
    let currentFilters = updatedFilters;
    let floor = currentFilters?.floorHeights
      ? currentFilters?.floorHeights.split(",")
      : [];
    if (checked) {
      // If checked is true, add the name to the array (if it's not already included)
      if (!floor.includes(name)) {
        floor.push(name);
      }
    } else {
      // If checked is false, remove the name from the array
      floor = floor.filter((flor) => flor !== name);
    }

    // Convert the array back into a comma-separated string
    const updatedFloor = floor.join(",");
    currentFilters = { ...currentFilters, floorHeights: updatedFloor };
    if (!updatedFloor) {
      delete currentFilters.floorHeights;
    }

    setFilters(currentFilters);
    if (onChange) {
      onChange(currentFilters);
    }
  }
  function onChangeSize(value) {
    let currentFilters = updatedFilters;
    currentFilters = { ...currentFilters, maxSize: value };

    if (currentFilters?.maxSize === "any") {
      delete currentFilters.maxSize;
    }
    setFilters(currentFilters);
    if (onChange) {
      onChange(currentFilters);
    }
  }
  function handleChangePrice(e, value) {
    let currentFilters = updatedFilters;
    currentFilters = {
      ...currentFilters,
      minPrice: value[0],
      maxPrice: value[1],
    };

    setFilters(currentFilters);
    if (onChange) {
      onChange(currentFilters);
    }
  }

  return (
    <div className="  w-full ">
      {filterData.map((group, index) => {
        if (group.title === "budget") {
          return (
            <Box
              border={1}
              borderColor="grey.400"
              borderRadius="8px"
              padding="10px"
            >
              <div className="text-xs text-white">Budget</div>
              <MuiSlider
                value={[updatedFilters?.minPrice, updatedFilters?.maxPrice]}
                onChange={handleChangePrice}
                min={minPrice}
                max={maxPrice}
                displayLabel="off"
              />
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography variant="body2" color="white">
                  ${updatedFilters?.minPrice}
                </Typography>
                <Typography variant="body2" color="white">
                  $
                  {updatedFilters?.maxPrice === maxPrice
                    ? `${updatedFilters?.maxPrice}+`
                    : updatedFilters?.maxPrice}
                </Typography>
              </Box>
            </Box>
          );
        }
        if (group.title === "size") {
          return (
            <div className="mt-3">
              <div className="text-xs mb-3">Size (10-60 sqf)</div>
              <MuiButtonGroup
                defaultValue={updatedFilters?.maxSize}
                onSelect={onChangeSize}
                btns={[
                  { label: "Any", value: "any" },
                  { label: "10", value: "10" },
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                  { label: "60", value: "60" },
                ]}
              />
            </div>
          );
        }
        return (
          <div key={index} className="mt-4">
            <Title titleStyle={group.titleStyle}>{group.title}</Title>
            {group.checkboxes.map((check, idx) => (
              <CustomCheckbox
                onChange={group.dataKey ? handleChangeFloor : handleChange}
                key={idx}
                label={check.label}
                name={check.name}
                value={
                  group.dataKey
                    ? updatedFilters?.[group.dataKey] &&
                      updatedFilters?.[group.dataKey].includes(check.name)
                    : updatedFilters?.[check.name] || false
                }
              />
            ))}
          </div>
        );
      })}
      <Title titleStyle={"my-2"}>Property type</Title>
      <Dropdown
        defaultValue={updatedFilters?.propertyType}
        options={homeTypes}
        onChange={handleChange}
      />
    </div>
  );
};

export default RentBuyAdvanceFilter;
