import React, { useState } from "react";
import Logo from "../Logo";
import IconCircular from "../IconCircular/IconCircular";
import { FiMoreVertical } from "react-icons/fi";
import { Popover, Button, Divider } from "@mui/material";
import { supabase } from "../../utils/helpers/supabaseClient";
import { Link, useNavigate } from "react-router-dom";
import { setActiveScreenAction } from "../../store/Common/commonActions";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeScreen, setActiveScreen] = useState("search");
  const layout = useSelector((state) => state.layout.col);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      // Handle logout success if needed
      localStorage.removeItem("user");
    }
    handleClose(); // Close the popover after logout
  };

  function handleChangePass() {
    navigate("/reset-password");
  }
  function handleToggleScreen() {
    setActiveScreen(activeScreen === "save" ? "search" : "save");
    dispatch(
      setActiveScreenAction(activeScreen === "save" ? "search" : "save")
    );
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (layout === 3) {
    return null;
  }

  return (
    <header className="flex flex-col bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE] h-20 max-h-20 text-white px-4">
      <div className="flex flex-row flex-1 w-full justify-between items-center ">
        <div className="flex items-center gap-2">
          <Link
            to={"/home"}
            className="flex flex-row items-center gap-3 cursor-pointer no-underline"
          >
            <Logo classes={"w-12 h-12 cur"} />
            <span className="text-secondary-l0 font-semibold !no-underline">
              LifeMatch
            </span>
          </Link>
          {layout === 0 && (
            <div
              className="bg-[#fff] px-2 rounded-md text-xs py-2 cursor-pointer"
              onClick={handleToggleScreen}
            >
              {activeScreen === "save" ? "Search new listing" : "Save list"}
            </div>
          )}
        </div>
        <div className="flex flex-row">
          <div className="flex flex-row items-center gap-3">
            <IconCircular
              alt={"language"}
              className={"w-10 h-10 cursor-pointer"}
              key={"language"}
              src={"https://picsum.photos/200"}
            />
            <FiMoreVertical
              className="text-secondary-l0 cursor-pointer"
              size={40}
              onClick={handleMoreClick}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="flex flex-col  p-2">
                <div
                  onClick={handleChangePass}
                  className="text-xs cursor-pointer mb-2"
                >
                  Change password
                </div>
                <Divider orientation="vertical" flexItem />
                <div
                  onClick={handleLogout}
                  className="text-xs cursor-pointer mb-2"
                >
                  Logout
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
